import { lazy } from "react";

const SignIn = lazy(() => import("@view/Auth/SignIn"));
const Forgot = lazy(() => import("@view/Auth/Forgot"));
const Reset = lazy(() => import("@view/Auth/Reset"));
const Dashboard = lazy(() => import("@view/Dashboard"));
const SimpleCrud = lazy(() => import("@view/SimpleCrud"));
const Users = lazy(() => import("@view/Users"));
const PersonCrud = lazy(() => import("@view/PersonCrud"));
const Project = lazy(() => import("@view/Projects"));
const CreateOrEdit = lazy(() => import("@view/Projects/CreateOrEdit"));
const FormPayment = lazy(() => import("@/view/FormPayment"));

export default [
  {
    path: "/app/dashboard",
    exact: true,
    layout: "default",
    component: Dashboard,
    sidebar: true,
    icon: "dashboard",
    name: "Dashboard",
  },
  {
    path: "/app/ambiences",
    layout: "default",
    component: SimpleCrud,
    sidebar: true,
    icon: "apartment",
    name: "Ambientes",
  },
  {
    path: "/app/styles",
    layout: "default",
    component: SimpleCrud,
    sidebar: true,
    icon: "style",
    name: "Estilos",
  },
  {
    path: "/app/projects",
    layout: "default",
    component: Project,
    sidebar: true,
    exact: true,
    icon: "home_outllined",
    name: "Projetos",
  },
  {
    path: "/app/projects/create",
    layout: "default",
    exact: true,
    component: CreateOrEdit,
  },
  {
    path: "/app/projects/:id",
    layout: "default",
    exact: true,
    component: CreateOrEdit,
  },
  {
    path: "/app/clients",
    layout: "default",
    component: PersonCrud,
    sidebar: true,
    icon: "people",
    name: "Clientes",
  },
  {
    path: "/app/suppliers",
    layout: "default",
    component: PersonCrud,
    sidebar: true,
    icon: "emoji_transportation_outlined",
    name: "Fornecedores",
  },
  {
    path: "/app/architects",
    layout: "default",
    component: PersonCrud,
    sidebar: true,
    icon: "group_outlined",
    name: "Parceiros",
  },
  {
    path: "/app/users",
    layout: "default",
    component: Users,
    sidebar: true,
    icon: "person",
    name: "Usuários",
  },
  {
    path: "/app/formpayments",
    component: FormPayment,
    icon: "payment",
    sidebar: true,
    layout: "default",
    name: "Forma de pagamento",
  },
  {
    path: "/auth/signin",
    component: SignIn,
    layout: "auth",
    public: true,
  },
  {
    path: "/auth/forgot-password",
    component: Forgot,
    layout: "auth",
    public: true,
  },
  {
    path: "/auth/reset-password",
    component: Reset,
    layout: "auth",
    public: true,
  },
];
