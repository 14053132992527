// @ts-nocheck
import React, { createContext, useReducer, useContext, useState, useEffect } from "react";

import localforage from "localforage";
import PropTypes from "prop-types";

import api from "@shared/api";
import { AUTH_TOKEN } from "@shared/const";
import permissions from "@shared/data/permissions";
import history from "@shared/history";

const UserStateContext = createContext();
const UserDispatchContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_AUTH":
      return { ...state, auth: action.data, showComfirm: action.showComfirm || false };
    case "SET_ERROR":
      return { ...state, error: action.error };
    case "SHOW_CONFIRM":
      // console.info(action);
      return { ...state, showComfirm: action.data };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const [state, dispatch] = useReducer(userReducer, {
    auth: null,
    error: null,
    showComfirm: false,
  });

  useEffect(() => {
    const getAuth = async () => {
      const auth = await localforage.getItem(AUTH_TOKEN);
      dispatch({ type: "SET_AUTH", data: auth });
      setLoading(false);
    };

    getAuth();
  }, [setLoading]);

  return loading ? null : (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }

  return context;
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }

  return context;
};

const login = async (dispatch, email, password) => {
  try {
    const auth = await api.post("signin", { email, password }).then(res => res.data);
    if (auth.user && !auth.user.person) {
      auth.user.person = {};
      auth.user.permissions = {};
    }

    if (auth.user && !auth.user.admin) {
      switch (true) {
        case !!auth.user.person.client:
          auth.user.permissions = permissions.client;
          break;
        case !!auth.user.person.supplier:
          auth.user.permissions = permissions.supplier;
          break;
        case !!auth.user.person.architect:
          auth.user.permissions = permissions.architect;
          break;
        default:
          auth.user.permissions = permissions.user;
          break;
      }
    }

    await localforage.setItem(AUTH_TOKEN, auth);
    dispatch({ type: "SET_AUTH", data: auth });
    history.push("/");

    return auth;
  } catch (error) {
    dispatch({ type: "SET_ERROR", error });
  }
};

const logout = async dispatch => {
  await localforage.removeItem(AUTH_TOKEN);
  dispatch({ type: "SET_AUTH", data: null, showComfirm: false });
  history.push("/auth/signin");
};

const clearError = async dispatch =>
  dispatch({ type: "SET_ERROR", error: null, showComfirm: false });

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  login,
  logout,
  clearError,
  UserStateContext,
  UserDispatchContext,
};
