import React from "react";
import { useFormContext } from "react-hook-form";

import PropTypes from "prop-types";

import { TextField as MUITextField } from "@material-ui/core";
import { form } from "@shared/utils";

import { PhoneMask, CpfMask, CnpjMask, MoneyMask } from "./Masks";

const TextField = ({
  label,
  name,
  fullWidth = false,
  placeholder,
  type = "text",
  margin = "normal",
  disabled = false,
  autoFocus = false,
  multiline = false,
  rows = 3,
  rowsMax = 5,
  inputProps = {},
  nativeInputProps = {},
  defaultValue = null,
  mask = null,
  onChange = undefined,
}) => {
  const { errors, register } = useFormContext();

  const getMaskProps = () => {
    switch (mask) {
      case "phone":
        return { inputComponent: PhoneMask };
      case "cpf":
        return { inputComponent: CpfMask };
      case "cnpj":
        return { inputComponent: CnpjMask };
      case "money":
        return { inputComponent: MoneyMask };
      default:
        return {};
    }
  };

  return (
    <>
      <MUITextField
        error={Boolean(errors[name])}
        label={label}
        helperText={form.getErrorMessage(errors, name)}
        inputProps={nativeInputProps}
        InputProps={Object.assign({}, inputProps, getMaskProps())}
        onChange={onChange}
        inputRef={register()}
        // @ts-ignore
        margin={margin}
        variant="outlined"
        type={type}
        placeholder={placeholder}
        fullWidth={fullWidth}
        name={name}
        disabled={disabled}
        autoFocus={autoFocus}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        defaultValue={defaultValue}
      />
    </>
  );
};

TextField.propTypes = {
  margin: PropTypes.oneOf(["dense", "normal", "none"]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  inputProps: PropTypes.shape({}),
  defaultValue: PropTypes.any,
  mask: PropTypes.oneOf(["phone", "cpf", "cnpj", "money"]),
  nativeInputProps: PropTypes.object,
  onChange: PropTypes.func,
};

export default TextField;
