export default {
  password: "senha",
  email: "email",
  attachments: "anexos",
  rule: "permissão",
  ambiences: "ambientes",
  styles: "estilos",
  ambience: "ambiente",
  style: "estilo",
  description: "descrição",
  users: "usuários",
  user: "usuário",
  username: "Nome de usuário",
  clients: "clientes",
  client: "cliente",
  client_id: "cliente",
  suppliers: "fornecedores",
  supplier: "fornecedor",
  architects: "parceiros",
  architect: "parceiro",
  name: "Nome",
  cpfcnpj: "CPF / CNPJ",
  address: "endereço",
  state_id: "estado",
  city_id: "cidade",
  comment: "comentário",
  ambience_id: "ambiente",
  inspirations: "imagens",
  characters: "caracteres",
  items: "itens",
  supplier_id: "fornecedor",
  price: "valor",
  attachment: "anexo",
  formpayments: "Formas de pagamento",
  formpayment: "forma de pagamento",
  manufacturer: "fabricante",
};
