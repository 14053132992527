import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Loader = styled.div.attrs(({ show = false }) => ({ show }))`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(250, 250, 250, 0.7);
  top: 0;
  left: 0;
  display: ${props => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
