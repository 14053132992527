// @ts-nocheck
import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2";

import { Button } from "@material-ui/core";
import {
  NotificationsNone as NotificationsIcon,
  ThumbUp as ThumbUpIcon,
  ShoppingCart as ShoppingCartIcon,
  LocalOffer as TicketIcon,
  BusinessCenter as DeliveredIcon,
  SmsFailed as FeedbackIcon,
  DiscFull as DiscIcon,
  Email as MessageIcon,
  Report as ReportIcon,
  Error as DefenceIcon,
  AccountBox as CustomerIcon,
  Done as ShippedIcon,
  Publish as UploadIcon,
  Error as ErrorIcon,
  Check as SuccessIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";

import { Typography } from "../Wrappers";
import useStyles from "./styles";

const typesIcons = {
  "e-commerce": <ShoppingCartIcon />,
  notification: <NotificationsIcon />,
  offer: <TicketIcon />,
  info: <ThumbUpIcon />,
  message: <MessageIcon />,
  feedback: <FeedbackIcon />,
  customer: <CustomerIcon />,
  shipped: <ShippedIcon />,
  delivered: <DeliveredIcon />,
  defence: <DefenceIcon />,
  report: <ReportIcon />,
  upload: <UploadIcon />,
  disc: <DiscIcon />,
  error: <ErrorIcon />,
  success: <SuccessIcon />,
};

const getIconByType = (type = "offer") => {
  return typesIcons[type];
};

const Notification = ({
  variant,
  type,
  color,
  className,
  shadowless,
  typographyVariant,
  message,
  extraButton,
  extraButtonClick,
}) => {
  var classes = useStyles();
  var theme = useTheme();

  const icon = getIconByType(type);
  const iconWithStyles = React.cloneElement(icon, {
    style: {
      color: variant !== "contained" && theme.palette[color] && theme.palette[color].main,
    },
  });

  return (
    <div
      className={classnames(classes.notificationContainer, className, {
        [classes.notificationContained]: variant === "contained",
        [classes.notificationContainedShadowless]: shadowless,
      })}
      style={{
        backgroundColor:
          variant === "contained" && theme.palette[color] && theme.palette[color].main,
      }}
    >
      <div
        className={classnames(classes.notificationIconContainer, {
          [classes.notificationIconContainerContained]: variant === "contained",
          [classes.notificationIconContainerRounded]: variant === "rounded",
        })}
        style={{
          backgroundColor:
            variant === "rounded" &&
            theme.palette[color] &&
            tinycolor(theme.palette[color].main)
              .setAlpha(0.15)
              .toRgbString(),
        }}
      >
        {iconWithStyles}
      </div>
      <div className={classes.messageContainer}>
        <Typography
          className={classnames({
            [classes.containedTypography]: variant === "contained",
          })}
          variant={typographyVariant}
          size={variant !== "contained" && !typographyVariant && "md"}
        >
          {message}
        </Typography>
        {extraButton && extraButtonClick && (
          <Button onClick={extraButtonClick} disableRipple className={classes.extraButton}>
            {extraButton}
          </Button>
        )}
      </div>
    </div>
  );
};

Notification.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.oneOf([
    "e-commerce",
    "notification",
    "offer",
    "info",
    "message",
    "feedback",
    "customer",
    "shipped",
    "delivered",
    "defence",
    "report",
    "upload",
    "disc",
    "error",
    "success",
  ]),
  color: PropTypes.string,
  className: PropTypes.string,
  shadowless: PropTypes.string,
  typographyVariant: PropTypes.string,
  message: PropTypes.any,
  extraButton: PropTypes.any,
  extraButtonClick: PropTypes.func,
};

export default Notification;
