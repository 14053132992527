import styled from "styled-components";

import { Button as BaseButton } from "@components/Wrappers";
import { InputLabel, Chip as BaseChip } from "@material-ui/core";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";

export const Dropzone = styled.div.attrs(({ height = 100, error, disabled }) => ({
  height,
  error,
  disabled,
}))`
  display: flex;
  color: gray;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: dashed 2px ${props => (props.error ? "#f44336" : "#bdbdbd")};
  width: 100%;
  height: ${props => `${props.height}px`};
  position: relative;
  opacity: ${props => (props.disabled ? 0.2 : 1)};
  margin-top: 1.5rem;

  p {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }
`;

export const UploadList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InsertDriveFileIcon = styled(InsertDriveFile)`
  font-size: 2rem;
`;

export const Label = styled(InputLabel)`
  transform: none;
`;

export const Button = styled(BaseButton)`
  margin-top: 0px !important;
`;

export const Chip = styled(BaseChip)`
  margin: 10px 0px;
  margin-right: 10px;
`;
