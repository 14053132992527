import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";

import PropTypes from "prop-types";

import { FormHelperText, FormControl } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { form } from "@shared/utils";

import * as S from "./styles";

const FileUploadField = ({
  name,
  label = null,
  disabled = false,
  multiple = false,
  height = 120,
  maxSize = null,
  dropLabel = "Solte os arquivos aqui...",
  dragLabel = "Arraste o arquivo aqui ou",
  buttonLabel = "Clique aqui",
  accept,
  fullWidth = false,
}) => {
  const [files, setFiles] = useState([]);
  const { errors, register, unregister, setValue } = useFormContext();

  useEffect(() => {
    register({ name });

    return () => unregister(name);
  }, [register, unregister, name]);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple,
    disabled,
    maxSize,
    accept,
    onDropAccepted: acceptedFiles => {
      const currentFiles = [...files];
      const tmpFiles = acceptedFiles.filter(
        it => !multiple || !Boolean(files && files.filter(f => f.name === it.name).length),
      );

      setFiles(multiple && files ? currentFiles.concat(tmpFiles) : tmpFiles);
    },
  });

  useEffect(() => {
    setValue(name, files);
  }, [files, name, setValue]);

  return (
    <FormControl error={Boolean(errors[name])} fullWidth={fullWidth}>
      {label && <S.Label htmlFor={name}>{label}</S.Label>}
      <S.Dropzone
        {...getRootProps({ className: "dropzone" })}
        height={height}
        error={Boolean(errors[name])}
        id={name}
      >
        <input {...getInputProps()} name={name} />
        {isDragActive ? (
          <p>{dropLabel}</p>
        ) : (
          <>
            <S.InsertDriveFileIcon />
            <p>{dragLabel}</p>
            <S.Button onClick={open} disabled={disabled} variant="contained" size="small">
              {buttonLabel}
            </S.Button>
          </>
        )}
      </S.Dropzone>
      {Boolean(errors[name]) && (
        <FormHelperText>{form.getErrorMessage(errors, name)}</FormHelperText>
      )}
      <S.UploadList>
        {Array.isArray(files) &&
          files.map((file, idx) => (
            <S.Chip
              icon={<AttachmentIcon />}
              key={idx}
              label={file.name}
              onDelete={() => {
                const tmpFiles = files.filter(it => it.name !== file.name);
                setFiles(tmpFiles);
              }}
            />
          ))}
      </S.UploadList>
    </FormControl>
  );
};

FileUploadField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  height: PropTypes.number,
  maxSize: PropTypes.number,
  dropLabel: PropTypes.string,
  dragLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  fullWidth: PropTypes.bool,
};

export default FileUploadField;
