export default {
  user: {
    dashboard: [],
    projects: ["create", "update", "finish"],
    ambiences: ["create", "update", "delete", "assign", "add_status", "send_final_file"],
    timeline: ["create", "update", "delete"],
    styles: [],
    clients: [],
    suppliers: [],
    architects: [],
  },
  supplier: {
    dashboard: [],
    projects: ["create", "update"],
    ambiences: ["create", "update"],
    timeline: [],
    clients: ["create"],
  },
  architect: {
    dashboard: [],
    projects: ["create", "update"],
    ambiences: ["create", "update", "add_status"],
    timeline: [],
    clients: ["create"],
  },
  client: {
    dashboard: [],
    projects: ["create", "update"],
    ambiences: ["create", "update", "delete"],
    timeline: ["reply"],
  },
};
