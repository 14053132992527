import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import MatxTheme from "matx/MatxLayout/MatxTheme/MatxTheme";
import { Store } from "matx/redux/Store";

import App from "@components/App";
import { LayoutProvider } from "@shared/context/LayoutContext";
import { UserProvider } from "@shared/context/UserContext";

import * as serviceWorker from "./serviceWorker";

// import "matx/_index.scss"
import "matx/styles/_app.scss";

const rootEl = document.getElementById("root");

const render = Component => (
  <UserProvider>
    <Provider store={Store}>
      <MatxTheme>
        <LayoutProvider>
          <Component/>
        </LayoutProvider>
      </MatxTheme>
    </Provider>
  </UserProvider>
);

ReactDOM.render(render(App), rootEl);

// @ts-ignore
if (process.env.NODE_ENV !== "production" && module.hot) {
  // @ts-ignore
  module.hot.accept("@components/App", () => {
    const NextApp = require("@components/App").default;
    ReactDOM.render(render(NextApp), rootEl);
  });
}

serviceWorker.register();
