import React, { Component } from "react";

import { isMobile } from "matx/utils";
import PropTypes from "prop-types";

class MatxSidenav extends Component {
  handleResizeRef;

  state = {
    mobile: isMobile(),
  };

  handleWindowResize = () => {
    return event => {
      console.info(event.target.innerWidth);
      // if (event.target.innerWidth < 768) {
      //   this.setState({ mobile: true });
      // } else {
      //   this.setState({ mobile: false });
      // }
      this.setState({ mobile: isMobile() });
    };
  };

  componentDidMount () {
    this.handleResizeRef = this.handleWindowResize();
    console.info(window);
    if (window) {
      window.addEventListener("resize", this.handleResizeRef);
    }
  }

  componentWillUnmount () {
    if (this.handleResizeRef) {
      window.removeEventListener("resize", this.handleResizeRef);
    }
  }

  render () {
    const { open, children, toggleSidenav, width = "220px", bgClass } = this.props;

    const { mobile } = this.state;

    return (
      <div className="flex h-full">
        <div
          className={`matx-sidenav bg-default ${bgClass}`}
          style={{ width: open || !mobile ? width : "0px" }}
        >
          {children}
        </div>
        {open && mobile && <div onClick={toggleSidenav} className="matx-sidenav-overlay" />}
      </div>
    );
  }
}

MatxSidenav.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.element,
  toggleSidenav: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bgClass: PropTypes.string,
};

export default MatxSidenav;
