import I18n from "i18n-js";

import ptBR from "./pt-BR";

I18n.fallbacks = true;
I18n.defaultLocale = "pt-BR";
// I18n.locale = navigator.language || "pt-BR";
I18n.locale = "pt-BR";

I18n.translations = {
  "pt-BR": ptBR,
};

export const setLocale = locale => (I18n.locale = locale);

export const getCurrentLocale = () => {
  const { locale } = I18n;
  if (locale.includes("en")) {
    return "en";
  }
  return "pt-BR";
};

// @ts-ignore
export default I18n.translate.bind(I18n);
