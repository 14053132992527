import React, { useState, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";

import PropTypes from "prop-types";

import Notification from "@components/Notification";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";

import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    notification: {
      display: "flex",
      alignItems: "center",
      background: "transparent",
      boxShadow: "none",
      overflow: "visible",
    },
    progress: {
      visibility: "hidden",
    },
    notificationComponent: {
      paddingRight: spacing(4),
    },
    toastsContainer: {
      width: 400,
      marginTop: spacing(4),
      marginRight: spacing(2),
      right: 0,
    },
    notificationCloseButton: {
      position: "absolute",
      right: spacing(3),
      color: "#FFF",
      opacity: 0.5,
      transition: "all 0.2s ease-in-out",

      "&:hover": {
        opacity: 1,
      },
    },
  }),
);

const colors = {
  error: "secondary",
  info: "primary",
  success: "success",
};

const useToast = () => {
  const [toastId, setToastId] = useState(null);
  // @ts-ignore
  const classes = useStyles();

  const showToast = useCallback(
    ({
      message = "",
      type = "success",
      variant = "contained",
      extraButton = null,
      extraButtonClick = null,
      onClose = null,
      autoClose = 5000,
      closeOnClick = false,
    }) => {
      if (message && !toastId) {
        const id = toast(
          <Notification
            type={type}
            color={colors[type]}
            variant={variant}
            message={message}
            extraButton={extraButton}
            extraButtonClick={extraButtonClick}
            className={classes.notificationComponent}
          />,
          {
            position: "top-right",
            onClose: () => {
              if (onClose) {
                onClose();
              }
              setToastId(null);
            },
            className: classes.notification,
            autoClose,
            closeOnClick,
          },
        );

        setToastId(id);
      }
    },
    [classes, setToastId, toastId],
  );

  const updateToast = useCallback(
    ({ message = "", type = "success", variant = "contained" }) => {
      if (toastId) {
        toast.update(toastId, {
          render: (
            <Notification message={message} type={type} color={colors[type]} variant={variant} />
          ),
          type: toast.TYPE[type],
        });
      }
    },
    [toastId],
  );

  const CloseButton = ({ closeToast = null }) => (
    <CloseIcon className={classes.notificationCloseButton} onClick={closeToast} />
  );

  CloseButton.propTypes = {
    closeToast: PropTypes.func,
  };

  const toastContainer = (
    <ToastContainer
      className={classes.toastsContainer}
      closeButton={<CloseButton />}
      hideProgressBar
      newestOnTop
      rtl={false}
    />
  );

  return { showToast, toastContainer, updateToast };
};

export default useToast;
