import { createStyles, makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(({ spacing, palette }) =>
  createStyles({
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: spacing(1),
      top: spacing(1),
      color: palette.grey[500],
      zIndex: 10,
    },
    dialogContent: {
      padding: spacing(3, 2),
    },
  }),
);

export default styles;
