import React from "react";

import Layout1 from "@/matx/MatxLayout/Layout1/Layout1";
import Fade from "@material-ui/core/Fade";
import { useToast } from "@shared/hooks";

const Layout = () => {
  const { toastContainer } = useToast();

  return (
    <Fade in>
      <div>
        {toastContainer}
        <Layout1 />
      </div>
    </Fade>
  );
};

export default Layout;
