import React from "react";

import PropTypes from "prop-types";

import logo from "@/assets/images/logo-noname.png";
const Brand = ({ children, styleImg, styleText }) => {
  return (
    <div className="flex items-center justify-between brand-area">
      <div className="flex items-center brand">
        <img src={logo} style={styleImg} alt="company-logo" />
        <span className="brand__text" style={styleText}>
          Projetta
        </span>
      </div>
      {children}
    </div>
  );
};

Brand.propTypes = {
  children: PropTypes.object,
  styleImg: PropTypes.object,
  styleText: PropTypes.object,
};

export default Brand;
