import React from "react";

import { classList } from "matx/utils";
import PropTypes from "prop-types";

import { Card, Icon, makeStyles, Typography } from "@material-ui/core";
const useClasses = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    fontSize: 40,
    marginRight: 5,
  },
}));
const SimpleCard = ({ children, title, subtitle, icon, value, onClick, className }) => {
  const classes = useClasses();
  return (
    <Card
      elevation={6}
      className={"px-6 py-5 h-full " + className}
      style={{ display: icon ? "flex" : undefined, flexDirection: "column", cursor: onClick ? "pointer" : undefined }}
      onClick={onClick}
    >
      <div style={{ display: "flex" }}>
        {icon && <Icon className={classes.icon}>{icon}</Icon>}
        <div>
          {title && (
            <div
              className={classList({
                "card-title": true,
                "mb-4": !subtitle,
              })}
            >
              {title}
            </div>
          )}
          {subtitle && <div className="card-subtitle">{subtitle}</div>}
        </div>
      </div>
      {(value || value === 0) && (
        <Typography variant="h6" className="text-primary font-medium">
          {value}
        </Typography>
      )}

      {children && children}
    </Card>

    // <Card className="play-card p-sm-24 bg-paper" elevation={6}>
    //   <div className="flex items-center">
    //     {icon && <Icon className={classes.icon}>{icon}</Icon>}
    //     <div className="ml-3">
    //       <Typography variant={"h6"}>{title}</Typography>
    //       {subtitle && (
    //         <div className="card-subtitle font-medium">
    //           <small className="text-muted">{subtitle}</small>
    //         </div>
    //       )}
    //       {value && <h6 className="m-0 mt-1 text-primary font-medium">{value}</h6>}
    //     </div>
    //     {children}
    //   </div>
    // </Card>
  );
};

export default SimpleCard;

SimpleCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  icon: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  className: PropTypes.string,
};
