// @ts-nocheck
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette, transitions }) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: spacing(1.5, 1.5),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: palette.common.white,
        "& + $track": {
          backgroundColor: palette.primary.main,
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: palette.primary.main,
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${palette.grey[400]}`,
      backgroundColor: palette.grey[50],
      opacity: 1,
      transition: transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }),
);
