import React from "react";

import routes from "@shared/data/routes";

import PrivateRoute from "./Private";
import PublicRoute from "./Public";

const renderRoutes = (layout, props) => {
  const filteredRoutes = routes.filter(r => r.layout === layout);

  return filteredRoutes.map((route, i) => {
    if (route.public) {
      return <PublicRoute key={i} {...route} {...props} />;
    }

    return <PrivateRoute key={i} {...route} {...props} />;
  });
};

export default renderRoutes;
