import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { MatxMenu } from "matx";
import Brand from "matx/MatxLayout/SharedCompoents/Brand";
import { setLayoutSettings } from "matx/redux/actions/LayoutActions";
import {
  // isMdScreen,
  classList,
} from "matx/utils";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {
  LayoutStateContext,
  LayoutDispatchContext,
  toggleAutoUpdateDashBoard,
} from "@/shared/context/LayoutContext";
import { UserStateContext, UserDispatchContext } from "@/shared/context/UserContext";
import { Icon, IconButton, MenuItem, Switch, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import { logoutUser } from "matx/redux/actions/UserActions";

import NotificationBar from "../SharedCompoents/NotificationBar";
import Profile from "../SharedCompoents/Profile";

// import ShoppingCart from "../SharedCompoents/ShoppingCart";

const styles = theme => ({
  topbar: {
    "& .topbar-hold": {
      backgroundColor: theme.palette.primary.main,
      height: "80px",
      "&.fixed": {
        boxShadow: theme.shadows[8],
        height: "64px",
      },
    },
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    minWidth: 185,
  },
});

class Layout1Topbar extends Component {
  state = {
    profileIsShow: false,
  };

  updateSidebarMode = sidebarSettings => {
    const { settings, setLayoutSettings: setLayoutSettingsProp } = this.props;

    setLayoutSettingsProp({
      ...settings,
      layout1Settings: {
        ...settings.layout1Settings,
        leftSidebar: {
          ...settings.layout1Settings.leftSidebar,
          ...sidebarSettings,
        },
      },
    });
  };

  handleSidebarToggle = () => {
    // const { settings } = this.props;
    // const { layout1Settings } = settings;

    // let mode;
    // if (isMdScreen()) {
    //   mode = "close";
    // } else {
    //   mode = layout1Settings.leftSidebar.mode === "full" ? "close" : "full";
    // }
    // console.info(layout1Settings.leftSidebar.mode);
    this.updateSidebarMode({ mode: "mobile" });
  };

  handleSignOut = dispatch => () => {
    dispatch({ type: "SHOW_CONFIRM", data: true });
  };

  handleShowProfile = show => {
    this.setState({ profileIsShow: show });
  };

  render () {
    const { classes, fixed } = this.props;

    return (
      <div className={`topbar ${classes.topbar}`}>
        <div className={classList({ "topbar-hold": true, fixed })}>
          <div className="flex justify-between items-center h-full">
            <div className="flex">
              <UserStateContext.Consumer>
                {({ auth }) => {
                  if (auth && auth.user && auth.user.person && auth.user.person.client) {
                    return (
                      <Brand
                        styleImg={{
                          maxWidth: "50px",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          padding: "5px",
                          marginRight: "10px",
                        }}
                        styleText={{
                          fontSize: "20px",
                          color: "white",
                          fontWeight: 600,
                        }}
                      />
                    );
                  }
                  if (auth && auth.user && auth.user.person && !auth.user.person.client) {
                    return (
                      <IconButton onClick={this.handleSidebarToggle} className="hide-on-pc">
                        <Icon>menu</Icon>
                      </IconButton>
                    );
                  }
                }}
              </UserStateContext.Consumer>
              {/* <div className="hide-on-mobile">
                <IconButton>
                  <Icon>mail_outline</Icon>
                </IconButton>

                <IconButton>
                  <Icon>web_asset</Icon>
                </IconButton>

                <IconButton>
                  <Icon>star_outline</Icon>
                </IconButton>
              </div> */}
            </div>
            <div className="flex items-center">
              <LayoutDispatchContext.Consumer>
                {dispatch => (
                  <LayoutStateContext.Consumer>
                    {state => (
                      <Tooltip
                        title={
                          (!state.isAutoUpdateDashBoard ? "Ativar" : "Desativar") +
                          " atualização automática"
                        }
                      >
                        <Switch
                          className="sidenav__toggle show-on-pc"
                          onChange={() => {
                            if (state.isAutoUpdateDashBoard) {
                              Swal.fire({
                                // title: "Atenção",
                                text: "Atualização automática desativada!",
                                toast: true,
                                timer: 5000,
                                icon: "warning",
                                position: "top-right",
                                timerProgressBar: true,
                              });
                            }
                            toggleAutoUpdateDashBoard(dispatch);
                          }}
                          checked={state.isAutoUpdateDashBoard}
                          color="secondary"
                        />
                      </Tooltip>
                    )}
                  </LayoutStateContext.Consumer>
                )}
              </LayoutDispatchContext.Consumer>
              <NotificationBar />
              <Profile
                settings={this.props.settings}
                open={this.state.profileIsShow}
                onClose={() => this.handleShowProfile(false)}
              />
              <MatxMenu
                menuButton={
                  this.props.user && this.props.user.photoURL ? (
                    <img
                      className="mx-2 align-middle circular-image-small cursor-pointer"
                      src={this.props.user.photoURL}
                      alt="user"
                    />
                  ) : (
                    <Icon
                      style={{ color: "white" }}
                      className="mx-2 align-middle circular-image-small cursor-pointer"
                    >
                      {" "}
                      face{" "}
                    </Icon>
                  )
                }
              >
                <MenuItem>
                  <Link className={classes.menuItem} to="/">
                    <Icon> home </Icon>
                    <span className="pl-4"> Dashboard </span>
                  </Link>
                </MenuItem>
                <MenuItem onClick={() => this.handleShowProfile(true)}>
                  <Icon> person </Icon>
                  <span className="pl-4"> Perfil </span>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Icon> settings </Icon>
                  <span className="pl-4"> Configurações </span>
                </MenuItem>
                <UserDispatchContext.Consumer>
                  {dispatch => (
                    <MenuItem onClick={this.handleSignOut(dispatch)} className={classes.menuItem}>
                      <Icon> power_settings_new </Icon>
                      <span className="pl-4"> Sair </span>
                    </MenuItem>
                  )}
                </UserDispatchContext.Consumer>
              </MatxMenu>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Layout1Topbar.propTypes = {
  setLayoutSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  classes: PropTypes.object,
  fixed: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = state => ({
  setLayoutSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  settings: state.layout.settings,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, { setLayoutSettings })(Layout1Topbar)),
);
