/* eslint-disable react/prop-types */
// @ts-nocheck
import React from "react";

import classnames from "classnames";

import {
  withStyles,
  Badge as BadgeBase,
  Typography as TypographyBase,
  Button as ButtonBase,
  Fab as FabBase,
  CircularProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTheme, makeStyles } from "@material-ui/styles";

const getColor = (color, theme, brigtness = "main") => {
  if (color && theme.palette[color] && theme.palette[color][brigtness]) {
    return theme.palette[color][brigtness];
  }
};

const getFontWeight = style => {
  switch (style) {
    case "light":
      return 300;
    case "medium":
      return 500;
    case "bold":
      return 600;
    default:
      return 400;
  }
};

const getFontSize = (size, variant = "", theme) => {
  var multiplier;

  switch (size) {
    case "sm":
      multiplier = 0.8;
      break;
    case "md":
      multiplier = 1.5;
      break;
    case "xl":
      multiplier = 2;
      break;
    case "xxl":
      multiplier = 3;
      break;
    default:
      multiplier = 1;
      break;
  }

  const defaultSize =
    variant && theme.typography[variant]
      ? theme.typography[variant].fontSize
      : theme.typography.fontSize + "px";

  return `calc(${defaultSize} * ${multiplier})`;
};

const createStyled = (styles, options) => {
  const Styled = props => {
    const { children, ...other } = props;
    return children(other);
  };

  return withStyles(styles, options)(Styled);
};

const useStyles = makeStyles(() => ({
  badge: {
    fontWeight: 600,
    height: 16,
    minWidth: 16,
  },
}));

const Badge = ({ children, colorBrightness = null, color = "primary", ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const Styled = createStyled({
    badge: {
      backgroundColor: getColor(color, theme, colorBrightness),
    },
  });

  return (
    <Styled>
      {styledProps => (
        <BadgeBase
          classes={{
            badge: classnames(classes.badge, styledProps.classes.badge),
          }}
          {...props}
        >
          {children}
        </BadgeBase>
      )}
    </Styled>
  );
};

const Typography = ({
  children,
  weight = null,
  size = "md",
  colorBrightness = null,
  color = "primary",
  ...props
}) => {
  const theme = useTheme();

  return (
    <TypographyBase
      style={{
        color: getColor(color, theme, colorBrightness),
        fontWeight: getFontWeight(weight),
        fontSize: getFontSize(size, props.variant, theme),
      }}
      {...props}
    >
      {children}
    </TypographyBase>
  );
};

const Button = ({
  children,
  color = null,
  className = "",
  loading = false,
  type = "button",
  ...props
}) => {
  const theme = useTheme();

  const Styled = createStyled({
    root: {
      color: getColor(color, theme),
    },
    rootCircular: {
      color: `${color ? "white" : theme.palette.text.primary} !important`,
      marginRight: 15,
      transition: "all 0.2s ease-in-out",
    },
    contained: {
      backgroundColor: getColor(color, theme),
      // boxShadow: theme.customShadows.widget,
      color: `${color ? "white" : theme.palette.text.primary} !important`,
      "&:hover": {
        backgroundColor: getColor(color, theme, "light"),
      },
      "&:disabled": {
        backgroundColor: getColor(color, theme),
        opacity: 0.9,
      },
    },
    outlined: {
      color: getColor(color, theme),
      borderColor: getColor(color, theme),
    },
    select: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  });

  return (
    <Styled>
      {({ classes }) => (
        <ButtonBase
          disableRipple
          disableFocusRipple
          classes={{
            contained: classes.contained,
            root: classes.root,
            outlined: classes.outlined,
          }}
          type={type}
          {...props}
          className={classnames(
            {
              [classes.select]: props.select,
            },
            className,
          )}
        >
          {loading && <CircularProgress classes={{ root: classes.rootCircular }} size={14} />}
          {children}
        </ButtonBase>
      )}
    </Styled>
  );
};

const AddFab = ({ color = "primary", className = "", loading = false, ...props }) => {
  const theme = useTheme();

  const Styled = createStyled({
    fab: {
      position: "fixed",
      top: "auto",
      left: "auto",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    rootCircular: {
      color: `${color ? "white" : theme.palette.text.primary} !important`,
      transition: "all 0.2s ease-in-out",
    },
  });

  return (
    <Styled>
      {({ classes }) => (
        <FabBase
          disableRipple
          disableFocusRipple
          color={color}
          className={classnames(classes.fab, className)}
          {...props}
        >
          {loading ? (
            <CircularProgress size={30} classes={{ root: classes.rootCircular }} />
          ) : (
            <AddIcon />
          )}
        </FabBase>
      )}
    </Styled>
  );
};

export { Badge, Typography, Button, AddFab };
