import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import PropTypes from "prop-types";

import { TextField, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { form } from "@shared/utils";

const AutocompleteField = ({
  label = null,
  name,
  id = null,
  placeholder,
  disabled = false,
  autoFocus = false,
  options = [],
  loading = false,
  multiple = false,
  fullWidth = false,
  onChange = null,
  defaultValue = null,
  disableClearable = false,
  noOptionsText = "Nenhuma opção",
  filterSelectedOptions = false,
}) => {
  const { errors, register, unregister, setValue, clearError } = useFormContext();

  useEffect(() => {
    register({ name });

    return () => unregister(name);
  }, [name, register, unregister]);

  useEffect(() => {
    if (defaultValue) {
      if (Array.isArray(defaultValue)) {
        return setValue(
          name,
          defaultValue.map(v => v.id),
        );
      }

      return setValue(name, defaultValue.id);
    }
  }, [defaultValue, setValue, name]);

  // hack to clear field when it has no value
  const key = useMemo(() => {
    return new Date().valueOf();
    // eslint-disable-next-line
  }, [defaultValue]);

  return (
    // @ts-ignore
    <Autocomplete
      key={key}
      id={`autocomplete-${id || name}`}
      disabled={disabled}
      multiple={multiple}
      defaultValue={defaultValue}
      onChange={(e, data) => {
        if (data) {
          if (Array.isArray(data)) {
            const tmpData = data.map(d => d.id);
            setValue(name, tmpData);
          } else {
            setValue(name, data.id);
          }

          clearError(name);
        } else {
          setValue(name, undefined);
        }

        if (onChange) {
          onChange(data ? data.id : undefined);
        }
      }}
      getOptionLabel={option => option.label}
      options={options}
      loading={loading}
      disableClearable={disableClearable}
      noOptionsText={noOptionsText}
      filterSelectedOptions={filterSelectedOptions}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          name={name}
          autoFocus={autoFocus}
          error={Boolean(errors[name])}
          helperText={form.getErrorMessage(errors, name)}
          placeholder={placeholder}
          fullWidth={fullWidth}
          variant="outlined"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

AutocompleteField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  disableClearable: PropTypes.bool,
  noOptionsText: PropTypes.string,
  filterSelectedOptions: PropTypes.bool,
  id: PropTypes.string,
};

export default AutocompleteField;
