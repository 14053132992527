import React from "react";

import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import BaseDialog from "./Base";

const ImageDialog = ({ open, url, onCancel, closeText = "OK", loading = false }) => (
  <BaseDialog
    open={open}
    onClose={onCancel}
    maxWidth="lg"
    actions={[{ label: closeText, onClick: onCancel, color: "secondary", disabled: loading }]}
  >
    <Typography gutterBottom>
      <img src={url} alt={url} style={{ width: "100%" }} />
    </Typography>
  </BaseDialog>
);

ImageDialog.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  closeText: PropTypes.string,
  loading: PropTypes.bool,
};

export default ImageDialog;
