import React from "react";

import Cleave from "cleave.js/react";
import PropTypes from "prop-types";

const CpfMask = ({ inputRef, defaultValue, ...rest }) => {
  const defaultProps = {};

  if (defaultValue) {
    defaultProps.value = defaultValue;
  }

  return (
    <Cleave
      htmlRef={inputRef}
      options={{
        blocks: [3, 3, 3, 2],
        delimiters: [".", ".", "-"],
      }}
      {...rest}
      {...defaultProps}
    />
  );
};

CpfMask.propTypes = {
  inputRef: PropTypes.any,
  defaultValue: PropTypes.any,
};

export default CpfMask;
