// @ts-nocheck
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) =>
  createStyles({
    rootControl: {
      marginTop: spacing(2),
    },
    rootGroup: {
      flexDirection: "row",
    },
  }),
);
