import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { merge } from "lodash";
import { setLayoutSettings, setDefaultSettings } from "matx/redux/actions/LayoutActions";
import { logoutUser } from "matx/redux/actions/UserActions";
import { isMdScreen, isMobile } from "matx/utils";
import PropTypes from "prop-types";

import { UserStateContext, UserDispatchContext } from "@/shared/context/UserContext";
import { Switch, Icon, Tooltip, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// import { MatxMenu } from "matx";
import SidenavTheme from "../MatxTheme/SidenavTheme/SidenavTheme";
import Brand from "../SharedCompoents/Brand";
import Sidenav from "../SharedCompoents/Sidenav";

const styles = () => ({});

const IconButtonWhite = withStyles(() => ({
  root: {
    backgroundColor: "transparent",
    padding: "5px",
  },
}))(IconButton);

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1rem",
  },
}))(Icon);

class Layout1Sidenav extends Component {
  state = {
    hidden: true,
    openConfirm: false,
  };

  // static contextType = UserStateContext;
  componentDidMount () {
    // CLOSE SIDENAV ON ROUTE CHANGE ON MOBILE
    this.unlistenRouteChange = this.props.history.listen(() => {
      if (isMdScreen()) {
        this.updateSidebarMode({ mode: "mobile" });
      }
    });

    setTimeout(() => {
      this.setState({ hidden: false });
    }, 400);
  }

  componentWillUnmount () {
    this.unlistenRouteChange();
  }

  updateSidebarMode = sidebarSettings => {
    const {
      settings,
      setLayoutSettings: setLayoutSettingsProp,
      setDefaultSettings: setDefaultSettingsProp,
    } = this.props;
    const updatedSettings = merge({}, settings, {
      layout1Settings: {
        leftSidebar: {
          ...sidebarSettings,
        },
      },
    });

    setLayoutSettingsProp(updatedSettings);
    setDefaultSettingsProp(updatedSettings);
  };

  handleSidenavToggle = () => {
    const {
      settings: {
        layout1Settings: {
          leftSidebar: { mode },
        },
      },
    } = this.props;

    console.log(mode);
    this.updateSidebarMode({ mode: mode === "compact" ? "full" : "compact" });
  };

  handleSignOut = dispatch => () => {
    dispatch({ type: "SHOW_CONFIRM", data: true });
  };

  verifyPermissionSideBar = ({ user }) => {
    console.log(user);
    const {
      settings: {
        layout1Settings: {
          leftSidebar: { mode },
        },
      },
    } = this.props;
    // console.info(user);
    if (user.person && user.person.client && mode !== "close") {
      this.updateSidebarMode({ mode: "close", hide: true });
    } else if (mode === "close" && (!user.person || !user.person.client) && !isMobile()) {
      this.updateSidebarMode({ mode: "compact", hide: false });
    }
  };

  renderLogoSwitch = () => (
    // Open Brand component file to replace logo and text
    <Brand>
      <Switch
        className="sidenav__toggle show-on-pc"
        onChange={this.handleSidenavToggle}
        checked={!(this.props.settings.layout1Settings.leftSidebar.mode === "full")}
        color="secondary"
      />
    </Brand>
  );

  renderUser = () => {
    return (
      <UserStateContext.Consumer>
        {({ auth }) => {
          const user = (auth && auth.user) || null;
          if (user) {
            this.verifyPermissionSideBar(auth);
          }
          return (
            <div className="sidenav__user">
              <div className="username-photo">
                {user && user.photoURL ? (
                  <img src={user.photoURL} alt="user" />
                ) : (
                  <Icon> face </Icon>
                )}
              </div>

              <div className="ml-4">
                <span className="username">
                  {/* <Icon>lock</Icon> */}
                  {user && user.username}
                </span>
                <div className="user__menu">
                  {/* <MatxMenu
                    menuButton={
                      <Tooltip title="Configurações">
                        <IconButtonWhite aria-label="Delete" className="" size="small">
                          <IconSmall> settings </IconSmall>
                        </IconButtonWhite>
                      </Tooltip>
                    }
                  >
                    <MenuItem className="flex items-center">
                      <Icon> home </Icon>
                      <span className="pl-4"> Dashboard </span>
                    </MenuItem>
                    <MenuItem className="flex items-center">
                      <Icon> settings </Icon>
                      <span className="pl-4"> Configurações da conta </span>
                    </MenuItem>
                  </MatxMenu> */}

                  {/* <Tooltip title="Perfil">
                    <IconButtonWhite aria-label="Delete" className="" size="small">
                      <IconSmall>person</IconSmall>
                    </IconButtonWhite>
                  </Tooltip> */}
                  <Tooltip title="Sair">
                    <UserDispatchContext.Consumer>
                      {dispatch => (
                        <IconButtonWhite
                          aria-label="Delete"
                          className=""
                          size="small"
                          onClick={this.handleSignOut(dispatch)}
                        >
                          <IconSmall>exit_to_app</IconSmall>
                        </IconButtonWhite>
                      )}
                    </UserDispatchContext.Consumer>
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        }}
      </UserStateContext.Consumer>
    );
  };

  render () {
    const { theme, settings } = this.props;
    const sidenavTheme = settings.themes[settings.layout1Settings.leftSidebar.theme] || theme;
    return (
      <SidenavTheme theme={sidenavTheme} settings={settings}>
        <div className="sidenav">
          <div
            className="sidenav__hold"
            style={{
              backgroundImage: `url(${settings.layout1Settings.leftSidebar.bgImgURL})`,
            }}
          >
            {!this.state.hidden && (
              <Fragment>
                {this.renderLogoSwitch()}
                <Sidenav>{this.renderUser()}</Sidenav>
              </Fragment>
            )}
          </div>
        </div>
      </SidenavTheme>
    );
  }
}

Layout1Sidenav.propTypes = {
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  history: PropTypes.object,
  theme: PropTypes.object,
};

const mapStateToProps = state => ({
  setDefaultSettings: PropTypes.func.isRequired,
  setLayoutSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: state.user,
  settings: state.layout.settings,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setLayoutSettings,
      setDefaultSettings,
      logoutUser,
    })(Layout1Sidenav),
  ),
);
