import React, { Suspense } from "react";
import { Switch } from "react-router-dom";

import logo from "@assets/images/logo.jpg";
import OptimizedImage from "@components/OptimizedImage";

import renderRoutes from "@components/routes";
import { Grid, Fade, Card } from "@material-ui/core";
import { useToast } from "@shared/hooks";


const AuthLayout = props => {
  // @ts-ignore
  const { toastContainer } = useToast();

  return (
    <Fade in>
      <div className="signup flex justify-center w-full h-full-screen">
      {toastContainer}
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-8 flex justify-center items-center h-full">
                  <OptimizedImage src={logo} alt="app logo" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full bg-light-gray position-relative">
                  <Suspense fallback={<div>loading...</div>}>
                    <Switch>{renderRoutes("auth", props)}</Switch>
                  </Suspense>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </Fade>
  );
};

export default AuthLayout;
