import React, { useState } from "react";

import PropTypes from "prop-types";

import BaseDialog from "./Base";

const FormDialog = ({
  open,
  children,
  title,
  onSubmit,
  onCancel,
  submitText = "Confirmar",
  cancelText = "Cancelar",
  validationSchema = {},
  maxWidth = "xs",
  loading = false,
}) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <BaseDialog
      open={open}
      title={title}
      onClose={onCancel}
      renderForm
      onSubmit={onSubmit}
      setSubmitting={setSubmitting}
      validationSchema={validationSchema}
      maxWidth={maxWidth}
      loading={loading}
      actions={[
        { label: cancelText, onClick: onCancel, variant: "text" },
        {
          label: submitText,
          type: "submit",
          disabled: submitting,
          loading: submitting,
        },
      ]}
    >
      {children}
    </BaseDialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.any,
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  validationSchema: PropTypes.any,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  loading: PropTypes.bool,
};

export default FormDialog;
