/* eslint-disable no-alert */
import axios from "axios";
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from "axios-extensions";
import localforage from "localforage";

import { SERVER_API_URL, AUTH_TOKEN } from "@shared/const";
import history from "@shared/history";

// const message = (...args) => {
//   window.alert(...args);
// };

const api = axios.create({
  baseURL: SERVER_API_URL,
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false }),
  ),
});

api.interceptors.request.use(async config => {
  const auth = await localforage.getItem(AUTH_TOKEN);
  if (auth) {
    config.headers.Authorization = `Bearer ${auth.token}`;
  }
  return config;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response && error.response.status === 401) {
      history.replace("/");
      if (error.response.data) {
        switch (error.response.data.code) {
          case "E_INVALID_AUTH":
            return Promise.reject("E-mail e/ou senha inválidos");
          default: {
            await localforage.removeItem(AUTH_TOKEN);
            window.location.reload(true);
            return Promise.reject("Sessão expirada");
          }
        }
      }
    } else if (error.response === undefined) {
      return Promise.reject("Conexão perdida");
      // message("Conexão perdida.");
    } else {
      if (error.response && error.response.data) {
        const data = error.response.data.error ? error.response.data.error : error.response.data;
        return Promise.reject(data);
      }

      return Promise.reject("Erro inesperado");
    }

    return Promise.reject(error);
  },
);

export default api;
