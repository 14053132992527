import React from "react";
import Scrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";

import { MatxSuspense } from "matx";
import { setLayoutSettings } from "matx/redux/actions/LayoutActions";
import { classList } from "matx/utils";
import PropTypes from "prop-types";

import { ConfirmDialog } from "@/components/Dialog";
import { logout, useUserState, useUserDispatch } from "@/shared/context/UserContext";
import { useToast } from "@/shared/hooks";
import renderRoutes from "@components/routes";
import { Box } from "@material-ui/core";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";

import Footer from "../SharedCompoents/Footer";
import Layout1Sidenav from "./Layout1Sidenav";
import Layout1Topbar from "./Layout1Topbar";

const styles = theme => {
  return {
    layout: {
      backgroundColor: theme.palette.background.default,
    },
  };
};

const Layout1 = props => {
  // eslint-disable-next-line react/prop-types
  const { settings, classes, theme } = props;
  const { layout1Settings } = settings;
  const topbarTheme = settings.themes[layout1Settings.topbar.theme];
  const layoutClasses = {
    // eslint-disable-next-line react/prop-types
    [classes.layout]: true,
    // eslint-disable-next-line react/prop-types
    [`${settings.activeLayout} sidenav-${layout1Settings.leftSidebar.mode} theme-${theme.palette.type} flex`]: true,
    "topbar-fixed": layout1Settings.topbar.fixed,
  };

  const { toastContainer } = useToast();
  const userState = useUserState();
  const dispatch = useUserDispatch();

  return (
    <div className={classList(layoutClasses)}>
      {layout1Settings.leftSidebar.show && <Layout1Sidenav />}

      <div className="content-wrap position-relative">
        {layout1Settings.topbar.show && layout1Settings.topbar.fixed && (
          <ThemeProvider theme={topbarTheme}>
            <Layout1Topbar
              // @ts-ignore
              fixed={true}
              className="elevation-z8"
            />
          </ThemeProvider>
        )}
        {toastContainer}

        <ConfirmDialog
          message="Deseja sair do sistema?"
          open={userState.showComfirm}
          onCancel={() => {
            dispatch({ type: "SHOW_CONFIRM", data: false });
          }}
          onConfirm={() => logout(dispatch)}
          confirmText="Sim"
          cancelText="Não"
        />

        {settings.perfectScrollbar && (
          <Scrollbar className="scrollable-content relative">
            {layout1Settings.topbar.show && !layout1Settings.topbar.fixed && (
              <ThemeProvider theme={topbarTheme}>
                <Layout1Topbar />
              </ThemeProvider>
            )}
            <div className="content" style={{ height: "calc(100vh - 64px)" }}>
              <Box padding={2}>
                <MatxSuspense>{<Switch>{renderRoutes("default", props)}</Switch>}</MatxSuspense>
              </Box>
            </div>
            <div className="my-auto" />
            {settings.footer.show && !settings.footer.fixed && <Footer />}
          </Scrollbar>
        )}
      </div>
    </div>
  );
};

Layout1.propTypes = {
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  setLayoutSettings: PropTypes.func.isRequired,
  settings: state.layout.settings,
});

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, { setLayoutSettings })(Layout1),
);
