import { useLayoutEffect, useState } from "react";

const useWindowSize = onUpdateSize => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (onUpdateSize) {
        return setSize(onUpdateSize);
      }

      return setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, [onUpdateSize, setSize]);

  return size;
};

export default useWindowSize;
