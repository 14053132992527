import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { ptBR } from "date-fns/locale";
import PropTypes from "prop-types";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { form, PtBRLocalizedUtils } from "@shared/utils";

const DatePickerField = ({
  label,
  name,
  fullWidth = false,
  autoOk = true,
  minDate = new Date("1900-01-01"),
  maxDate = new Date("2100-01-01"),
  placeholder,
  disabled = false,
  autoFocus = false,
  defaultValue = null,
  disableToolbar = false,
  format = "dd/MM/yyyy",
  variant = "dialog",
  clearable = false,
  disableFuture = false,
  disablePast = false,
}) => {
  const [selectedDate, handleDateChange] = useState(defaultValue);
  const { errors, register, unregister, setValue, clearError } = useFormContext();

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [name, register, unregister]);

  useEffect(() => {
    setValue(name, selectedDate);
    clearError(name);
  }, [selectedDate, name, clearError, setValue]);

  return (
    <MuiPickersUtilsProvider utils={PtBRLocalizedUtils} locale={ptBR}>
      <DatePicker
        autoOk={autoOk}
        clearable={clearable}
        format={format}
        placeholder={placeholder}
        label={label}
        name={name}
        fullWidth={fullWidth}
        // @ts-ignore
        variant={variant}
        disableToolbar={disableToolbar}
        value={selectedDate}
        autoFocus={autoFocus}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        inputVariant="outlined"
        error={Boolean(errors[name])}
        helperText={form.getErrorMessage(errors, name)}
        // @ts-ignore
        onChange={handleDateChange}
        disableFuture={disableFuture}
        disablePast={disablePast}
        cancelLabel="Cancelar"
      />
    </MuiPickersUtilsProvider>
  );
};

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  format: PropTypes.string,
  variant: PropTypes.oneOf(["dialog", "inline", "static"]),
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoOk: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  defaultValue: PropTypes.instanceOf(Date),
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
};

export default DatePickerField;
