import api from "@shared/api";

export const getData = async (url, id) => {
  const data = await api.get(`${url}/${id}`).then(res => res.data);
  return data;
};

export const getAllData = async url => {
  const data = await api.get(url).then(res => res.data);
  return data;
};

export const deleteData = async (url, id) => {
  return await api.delete(`${url}/${id}`);
};

export const addData = async (url, data, multipart = false) => {
  if (multipart) {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        return data[key].forEach(i => {
          if (typeof i === "object" && Object.prototype.hasOwnProperty.call(i, "filename")) {
            return formData.append(key, i, i.filename);
          }

          return formData.append(key, i);
        });
      }

      if (
        typeof data[key] === "object" &&
        Object.prototype.hasOwnProperty.call(data[key], "filename")
      ) {
        return formData.append(key, data[key], data[key].filename);
      }

      return formData.append(key, data[key]);
    });

    return await api
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(res => res.data);
  }

  return await api.post(url, data).then(res => res.data);
};

export const updateData = async (url, id, data) => {
  return await api.put(`${url}/${id}`, data).then(res => res.data);
};

export const uploadData = async (url, parameter, files) => {
  const formData = new FormData();
  files.forEach(f => formData.append(parameter, f, f.filename));

  return await api
    .post(url, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then(res => res.data);
};
