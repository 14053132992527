import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import PropTypes from "prop-types";

import { TextField, MenuItem, Divider, Typography } from "@material-ui/core";
import { form } from "@shared/utils";

import useStyles from "./styles";

const SelectField = ({
  name,
  id = null,
  label = null,
  fullWidth = false,
  options = [],
  placeholder = null,
  defaultValue = "",
  margin = "normal",
}) => {
  // @ts-ignore
  const classes = useStyles();
  const { errors, register, unregister, setValue, clearError } = useFormContext();
  const elId = id ? id : `select-${name}`;

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [name, register, unregister]);

  useEffect(() => {
    setValue(name, defaultValue);
    // eslint-disable-next-line
  }, [defaultValue, name]);

  return (
    <TextField
      id={elId}
      select
      variant="outlined"
      name={name}
      label={label}
      onChange={e => {
        setValue(name, e.target.value);
        clearError(name);
      }}
      // @ts-ignore
      margin={margin}
      fullWidth={fullWidth}
      placeholder={placeholder}
      error={Boolean(errors[name])}
      helperText={form.getErrorMessage(errors, name)}
      defaultValue={defaultValue}
    >
      {options.map((it, idx) =>
        it.group ? (
          <div key={idx}>
            <Typography variant="h5" component="p" className={classes.title}>
              {it.label}
            </Typography>
            <Divider />
          </div>
        ) : (
          <MenuItem value={it.value} key={idx}>
            {it.label}
          </MenuItem>
        ),
      )}
    </TextField>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  margin: PropTypes.oneOf(["dense", "normal", "none"]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
      group: PropTypes.bool,
      original: PropTypes.any,
    }),
  ),
  defaultValue: PropTypes.string,
};

export default SelectField;
