// @ts-nocheck
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ customShadows, spacing }) =>
  createStyles({
    notificationContainer: {
      display: "flex",
      alignItems: "center",
    },
    notificationContained: {
      borderRadius: 45,
      height: 45,
      // boxShadow: customShadows.widgetDark,
    },
    notificationContainedShadowless: {
      boxShadow: "none",
    },
    notificationIconContainer: {
      minWidth: 45,
      height: 45,
      borderRadius: 45,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 24,
    },
    notificationIconContainerContained: {
      fontSize: 18,
      color: "#FFFFFF80",
    },
    notificationIconContainerRounded: {
      marginRight: spacing(2),
    },
    containedTypography: {
      color: "white",
    },
    messageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexGrow: 1,
    },
    extraButton: {
      color: "white",
      "&:hover, &:focus": {
        background: "transparent",
      },
    },
  }),
);
