import { useCallback } from "react";

import { useUserState } from "@shared/context/UserContext";

const usePermission = () => {
  const { auth } = useUserState();

  const hasPermission = useCallback(
    permission => {
      if (auth) {
        if (auth.user.admin) {
          return true;
        }

        const {
          user: { permissions },
        } = auth;

        const [section, action] = permission.split(".");
        if (action && permissions) {
          // console.log(
          //   permissions,
          //   section,
          //   action,
          //   permissions[section].includes(action),
          //   Object.prototype.hasOwnProperty.call(permissions, section) &&
          //     permissions[section].includes(action),
          // );

          return (
            Object.prototype.hasOwnProperty.call(permissions, section) &&
            permissions[section].includes(action)
          );
        }

        // console.log(
        //   permissions,
        //   section,
        //   Object.prototype.hasOwnProperty.call(permissions, section),
        // );

        return permissions && Object.prototype.hasOwnProperty.call(permissions, section);
      }

      return false;
    },
    [auth],
  );

  return { hasPermission };
};

export default usePermission;
