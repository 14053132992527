import styled from "styled-components";

export const Container = styled.div.attrs(({ position = "fixed" }) => ({ position }))`
  position: ${props => props.position};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;
