import React from "react";
import { useForm, FormContext } from "react-hook-form";

import PropTypes from "prop-types";

import { ContainerLoader } from "@components/Loaders";
import { Button } from "@components/Wrappers";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "./styles";

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const BaseDialog = ({
  open = false,
  maxWidth = "md",
  actions = [],
  loading = false,
  scroll = "body",
  onClose,
  children,
  className = "",
  fullWidth = true,
  title = "",
  renderForm = false,
  validationSchema = {},
  onSubmit = null,
  setSubmitting = null,
}) => {
  const methods = useForm({
    validationSchema,
  });
  const { handleSubmit, setError } = methods;
  const classes = useStyles();
  const internalSubmit = async (data, e) => {
    setSubmitting(true);

    e.preventDefault();

    try {
      await onSubmit(data);
    } catch (error) {
      if (error && error.errors) {
        if (Array.isArray(error.errors)) {
          error.errors.forEach(({ field, message }) => setError(field, "urlNotIncludes", message));
        }
      }
    } finally {
      setSubmitting(false);
    }

    setSubmitting(false);
  };

  const content = (
    <ContainerLoader loading={loading}>
      {children !== null && children !== undefined && (
        <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      )}
      {actions !== null && actions !== undefined && (
        <DialogActions>
          {actions.map((action, index) => (
            <Button key={index} variant="contained" color="primary" {...action}>
              {action.label}
            </Button>
          ))}
        </DialogActions>
      )}
    </ContainerLoader>
  );

  const form = (
    <FormContext {...methods}>
      <form noValidate onSubmit={handleSubmit(internalSubmit)}>
        {content}
      </form>
    </FormContext>
  );

  return (
    // @ts-ignore
    <Dialog
      open={open}
      onClose={onClose}
      scroll={scroll}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      className={className}
      fullWidth={fullWidth}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{title}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {renderForm ? form : content}
    </Dialog>
  );
};

BaseDialog.propTypes = {
  open: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  scroll: PropTypes.oneOf(["body", "paper"]),
  actions: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  renderForm: PropTypes.bool,
  validationSchema: PropTypes.any,
  onSubmit: PropTypes.func,
  setSubmitting: PropTypes.func,
};

export default BaseDialog;
