import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@assets/app.scss";
import { DefaultLayout, AuthLayout } from "@components/layout";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/app" render={props => <DefaultLayout {...props} />} />
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <Redirect from="/" to="/auth/signin" />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
