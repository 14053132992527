// @ts-nocheck
import React, { createContext, useReducer, useContext } from "react";

import PropTypes from "prop-types";

const LayoutStateContext = createContext();
const LayoutDispatchContext = createContext();

const layoutReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case "TOGGLE_AUTO_UPDATE":
      return { ...state, isAutoUpdateDashBoard: !state.isAutoUpdateDashBoard };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(layoutReducer, {
    isSidebarOpened: true,
    isAutoUpdateDashBoard: false,
    intervalDashBoardUpdate: 5 * 1000 * 60,
  });

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useLayoutState = () => {
  const context = useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }

  return context;
};

const useLayoutDispatch = () => {
  const context = useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }

  return context;
};

const toggleSidebar = dispatch =>
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });

const toggleAutoUpdateDashBoard = dispatch =>
  dispatch({
    type: "TOGGLE_AUTO_UPDATE",
  });

export {
  LayoutProvider,
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  toggleAutoUpdateDashBoard,
  LayoutStateContext,
  LayoutDispatchContext,
};
