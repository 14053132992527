import { format } from "date-fns-tz";
// import { ptBR } from "date-fns/locale";

import DateFnsUtils from "@date-io/date-fns";
import _t from "@shared/locales";

export const string = {
  capitalize: value => {
    if (typeof value !== "string") {
      return "";
    }

    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  formatPhone: phone => {
    if (!phone) {
      return "";
    }

    const regex = phone.trim().lenght < 11 ? /(\d{2})(\d{4})(\d{4})/ : /(\d{2})(\d{5})(\d{4})/;

    return phone.replace(regex, "($1) $2-$3");
  },
};

export const validate = {
  email: value => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value),
};

export const date = {
  format: (d, formatStr = "PP", timeZone = "America/Cuiaba") => {
    return format(d, formatStr, {
      // locale: ptBR,
      timeZone,
    });
  },
};

export const form = {
  getErrorMessage: (errors, key) => {
    if (errors && errors[key]) {
      switch (errors[key].type) {
        case "required":
          return `${string.capitalize(_t(key))} é obrigatório`;
        case "email":
          return "Informe um email válido";
        case "max": {
          const max = errors[key].message.match(/\d+/g)[0];
          const type = errors[key].message.split(" ").reverse()[0];
          return `${string.capitalize(_t(key))} deve ter no máximo ${max} ${_t(type)}`;
        }
        case "min": {
          const min = errors[key].message.match(/\d+/g)[0];
          const type = errors[key].message.split(" ").reverse()[0];
          return `${string.capitalize(_t(key))} deve ter no mínimo ${min} ${_t(type)}`;
        }
        case "oneOf":
        case "cpfNotValid":
        case "cnpjNotValid":
        case "urlNotIncludes": {
          return errors[key].message ? errors[key].message : "";
        }
        case "url":
          return "Informe uma url válida";
        default:
          return "";
      }
    }

    return "";
  },
};

export class PtBRLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText (d) {
    return format(d, "d MMM yyyy", { locale: this.locale });
  }
}

export const isImage = path => {
  const ext = [
    "apng",
    "bmp",
    "gif",
    "ico",
    "cur",
    "jpg",
    "jpeg",
    "jfif",
    "pjpeg",
    "pjp",
    "png",
    "svg",
    "tif",
    "tiff",
  ];
  return ext.includes(path.substr(path.lastIndexOf(".") + 1));
};

export const numberFormat = Intl.NumberFormat("pt-br", { style: "currency", currency: "BRL" });

export const formatToDecimal = str => {
  if (typeof str !== "string") {
    str = str.toString();
  }

  return str.replace(/[.]/g, "").replace(/[,]/g, ".");
};
