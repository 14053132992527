import React from "react";

import PropTypes from "prop-types";

import { CircularProgress, Box } from "@material-ui/core";

import * as S from "./styles";

const ContainerLoader = ({ children, loading = false, loadingMessage = null, style = {} }) => (
  <S.Container style={style}>
    {children}
    <S.Loader show={loading}>
      <CircularProgress />
      {loadingMessage && <Box mt={2}>{loadingMessage}</Box>}
    </S.Loader>
  </S.Container>
);

ContainerLoader.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.any,
  style: PropTypes.shape({}),
};

export default ContainerLoader;
