import React from "react";

import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import BaseDialog from "./Base";

const ConfirmDialog = ({
  open,
  message,
  onConfirm,
  onCancel,
  confirmText = "Confirmar",
  cancelText = "Cancelar",
  loading = false,
}) => (
  <BaseDialog
    open={open}
    onClose={onCancel}
    maxWidth="xs"
    actions={[
      { label: cancelText, onClick: onCancel, color: "secondary", disabled: loading },
      {
        label: confirmText,
        onClick: onConfirm,
        loading,
      },
    ]}
  >
    <Typography gutterBottom>{message}</Typography>
  </BaseDialog>
);

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  loading: PropTypes.bool,
};

export default ConfirmDialog;
