import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import PropTypes from "prop-types";

import { FormControl, FormControlLabel, Switch, FormHelperText } from "@material-ui/core";
import { form } from "@shared/utils";

import useStyles from "./styles";

const SwitchField = ({
  label,
  name,
  disabled = false,
  autoFocus = false,
  defaultChecked = false,
  onChange = null,
}) => {
  const { errors, register, unregister, setValue, clearError } = useFormContext();

  const classes = useStyles();

  useEffect(() => {
    register({ name });

    return () => unregister(name);
  }, [register, name, unregister]);

  return (
    <FormControl error={Boolean(errors[name])}>
      <FormControlLabel
        label={label}
        control={
          <Switch
            name={name}
            autoFocus={autoFocus}
            disabled={disabled}
            defaultChecked={defaultChecked}
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            onChange={e => {
              setValue(name, e.target.checked);
              clearError(name);
              if (onChange) {
                onChange(e.target.checked);
              }
            }}
          />
        }
      />
      {Boolean(errors[name]) && (
        <FormHelperText>{form.getErrorMessage(errors, name)}</FormHelperText>
      )}
    </FormControl>
  );
};

SwitchField.propTypes = {
  label: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SwitchField;
