import React, { createElement } from "react";
import LazyLoad from "react-lazyload";

import cls from "classnames";
import PropTypes from "prop-types";

const AmpImage = ({ srcWebp, src, alt, width, height, resize, className }) =>
  srcWebp !== null && srcWebp !== undefined ? (
    <amp-image
      alt={alt}
      width={width}
      height={height}
      src={resize ? srcWebp.src : srcWebp}
      srcSet={resize ? srcWebp.srcSet : null}
      className={className}
    >
      <amp-image
        alt={alt}
        width={width}
        height={height}
        src={resize ? src.src : src}
        srcSet={resize ? src.srcSet : null}
      ></amp-image>
    </amp-image>
  ) : (
    <amp-image
      alt={alt}
      width={width}
      height={height}
      src={resize ? src.src : src}
      srcSet={resize ? src.srcSet : null}
      className={className}
    ></amp-image>
  );

AmpImage.propTypes = {
  /** Image's source path */
  src: PropTypes.any,
  /** Image's webp source path */
  srcWebp: PropTypes.any,
  /** Alternative description for the image */
  alt: PropTypes.string,
  /** Image tag title */
  title: PropTypes.string,
  /** Image width */
  width: PropTypes.string,
  /** Image height */
  height: PropTypes.string,
  /** Resize an image */
  resize: PropTypes.bool,
  /** Custom classNames to put on the outermost tag */
  className: PropTypes.string,
};

const OptimizedImage = ({
  src,
  srcWebp,
  alt,
  title,
  width,
  height,
  amp,
  include,
  className,
  zoomOnHover,
  lazy,
  lazyHeight,
  lazyOnce,
  lazyOffset,
  resize,
  trace,
}) => {
  const getExt = () => {
    if (typeof src === "string" && src.includes(".")) {
      return src.split(".").pop();
    }

    if (
      typeof src === "object" &&
      Object.prototype.hasOwnProperty.call(src, "src") &&
      src.src.includes(".")
    ) {
      return src.src.split(".").pop();
    }

    return null;
  };

  if (amp) {
    return createElement(AmpImage, {
      srcWebp,
      src,
      alt,
      width,
      height,
      resize,
      className,
    });
  }

  if (getExt() === "svg") {
    if (include) {
      const inner = { __html: src };
      return <div dangerouslySetInnerHTML={inner} />;
    }
    if (trace) {
      const traceStyle = { backgroundImage: src.trace };
      return (
        <img
          src={src.src}
          style={traceStyle}
          width={width}
          height={height}
          alt={alt}
          title={title}
          className={className}
        />
      );
    }
  }

  const imgComponent = (
    <picture
      className={cls(className, { "optimized-image-zoom": zoomOnHover })}
      style={{ width, height }}
    >
      {srcWebp !== null && srcWebp !== undefined && (
        <source
          srcSet={
            resize && Object.prototype.hasOwnProperty.call(srcWebp, "srcSet")
              ? srcWebp.srcSet
              : srcWebp
          }
          type="image/webp"
        />
      )}
      <source
        srcSet={resize && Object.prototype.hasOwnProperty.call(src, "srcSet") ? src.srcSet : src}
        type={`image/${getExt()}`}
      />
      <img
        src={resize ? src.src : src}
        srcSet={resize && Object.prototype.hasOwnProperty.call(src, "srcSet") ? src.srcSet : null}
        width={width}
        height={height}
        alt={alt || ""}
        title={title || ""}
      />
    </picture>
  );

  if (lazy) {
    return (
      <LazyLoad height={lazyHeight || height} resize={resize} once={lazyOnce} offset={lazyOffset}>
        {imgComponent}
      </LazyLoad>
    );
  }

  return imgComponent;
};

OptimizedImage.propTypes = {
  /** Image's source path */
  src: PropTypes.any,
  /** Image's webp source path */
  srcWebp: PropTypes.any,
  /** Alternative description for the image */
  alt: PropTypes.string,
  /** Image tag title */
  title: PropTypes.string,
  /** Image width */
  width: PropTypes.string,
  /** Image height */
  height: PropTypes.string,
  /** Include the raw file directly (useful for SVG icons) */
  include: PropTypes.bool,
  /** Generate a low quality image placeholder */
  lqip: PropTypes.bool,
  /** Use traced outlines as loading placeholder */
  trace: PropTypes.bool,
  /** Resize an image */
  resize: PropTypes.bool,
  /** Is an amp page */
  amp: PropTypes.bool,
  /** Custom classNames to put on the outermost tag */
  className: PropTypes.string,
  /** Zoom on hover image */
  zoomOnHover: PropTypes.bool,
  /** Lazy load image */
  lazy: PropTypes.bool,
  /** Lazy height */
  lazyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Lazy once */
  lazyOnce: PropTypes.bool,
  /** Lazy offset */
  lazyOffset: PropTypes.number,
};

export default OptimizedImage;
