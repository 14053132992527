import React from "react";

import Cleave from "cleave.js/react";
import PropTypes from "prop-types";

const MoneyMask = ({ inputRef, defaultValue, ...rest }) => {
  const defaultProps = {};

  if (defaultValue) {
    defaultProps.value = defaultValue;
  }

  return (
    <Cleave
      htmlRef={inputRef}
      options={{
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: ".",
      }}
      {...rest}
      {...defaultProps}
    />
  );
};

MoneyMask.propTypes = {
  inputRef: PropTypes.any,
  defaultValue: PropTypes.any,
};

export default MoneyMask;
