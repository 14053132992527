import React from "react";

import localforage from "localforage";
import PropTypes from "prop-types";
import * as yup from "yup";

import { FormDialog } from "@/components/Dialog";
import { TextField } from "@/components/Form";
import { AUTH_TOKEN } from "@/shared/const";
import { UserStateContext, useUserDispatch } from "@/shared/context/UserContext";
import { useToast } from "@/shared/hooks";
import { updateData } from "@/shared/services/simple-crud";
import { ThemeProvider } from "@material-ui/core/styles";

const Profile = ({ settings, open, onClose }) => {
  const { showToast } = useToast();
  const userDispatch = useUserDispatch();

  const handleSubmit = async (auth, fields) => {
    try {
      const user = await updateData("users", auth.user.id, fields);

      // Atualiza user logado
      auth.user = { ...auth.user, ...user };

      await localforage.setItem(AUTH_TOKEN, auth);
      userDispatch({ type: "SET_AUTH", data: auth });

      showToast({
        message: "Perfil atualizado com sucesso.",
      });

      onClose();
    } catch (error) {
      showToast({
        type: "error",
        message: "Falha ao atualizar o perfil.",
      });
    }
  };

  return (
    <ThemeProvider theme={settings.themes[settings.activeTheme]}>
      <UserStateContext.Consumer>
        {({ auth }) => {
          if (auth && auth.user) {
            return (
              <FormDialog
                title="Alteração de perfil"
                onCancel={() => {
                  onClose();
                }}
                onSubmit={fields => handleSubmit(auth, fields)}
                open={open}
                loading={false}
                maxWidth="sm"
                validationSchema={yup.object().shape({
                  username: yup
                    .string()
                    .required()
                    .min(3)
                    .max(30),
                  password: yup
                    .string()
                    .required()
                    .min(3)
                    .max(16),
                  passwordConfirmation: yup
                    .string()
                    .oneOf([yup.ref("password"), null], "Senhas não conferem"),
                })}
              >
                <TextField
                  name="username"
                  label="Nome de usuário"
                  defaultValue={auth.user.username}
                  fullWidth
                />
                <TextField name="password" label="Senha" type="password" fullWidth />
                <TextField
                  name="passwordConfirmation"
                  label="Confirme a senha"
                  type="password"
                  fullWidth
                />
              </FormDialog>
            );
          }
        }}
      </UserStateContext.Consumer>
    </ThemeProvider>
  );
};

Profile.defaultProps = {
  open: false,
};

Profile.propTypes = {
  settings: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Profile;
