const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: "close", // full, close, compact, mobile,
    theme: "slateDark1", // View all valid theme colors inside MatxTheme/themeColors.js
    // bgOpacity: .96, // 0 ~ 1
    bgImgURL: "/assets/sidebar/sidebar-bg-dark.jpg",
  },
  topbar: {
    show: true,
    fixed: false,
    theme: "slateDark1", // View all valid theme colors inside MatxTheme/themeColors.js
  },
  secondarySidebar: {
    show: false,
  },
};

export default Layout1Settings;
