import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import PropTypes from "prop-types";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { form } from "@shared/utils";

import useStyles from "./styles";

const RadioGroupField = ({
  label,
  name,
  disabled = false,
  options = [],
  fullWidth,
  defaultValue = null,
  onChange = null,
}) => {
  const { errors, register, unregister, setValue, clearError } = useFormContext();

  // @ts-ignore
  const classes = useStyles();

  useEffect(() => {
    register({ name });

    return () => unregister(name);
  }, [register, name, unregister]);

  return (
    <FormControl
      classes={{
        root: classes.rootControl,
      }}
      error={Boolean(errors[name])}
      component="fieldset"
      variant="outlined"
      disabled={disabled}
      fullWidth={fullWidth}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        classes={{
          root: classes.rootGroup,
        }}
        name={name}
        defaultValue={defaultValue}
        onChange={e => {
          setValue(name, e.target.value);
          if (e.target.value) {
            clearError(name);
          }

          if (onChange) {
            onChange(e.target.value);
          }
        }}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      {Boolean(errors[name]) && (
        <FormHelperText>{form.getErrorMessage(errors, name)}</FormHelperText>
      )}
    </FormControl>
  );
};

RadioGroupField.propTypes = {
  label: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

export default RadioGroupField;
