import React from "react";
import { Redirect, Route, useParams } from "react-router-dom";

import PropTypes from "prop-types";

import { useUserState } from "@shared/context/UserContext";
import { usePermission } from "@shared/hooks";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useUserState();
  const { id } = useParams();
  const { hasPermission } = usePermission();

  return (
    <Route
      {...rest}
      render={props => {
        const { pathname } = props.location;
        const [section, action] = pathname
          .split("/")
          .filter(i => i)
          .slice(1);

        if (!auth || !auth.token) {
          return (
            <Redirect
              to={{
                pathname: "/auth/signin",
                state: { from: props.location },
              }}
            />
          );
        }

        if (
          !hasPermission(`${section}${id || action ? `.${id !== null ? "update" : action}` : ""}`)
        ) {
          return (
            <Redirect
              to={{
                pathname: "/app/dashboard",
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default PrivateRoute;
