// @ts-nocheck
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) =>
  createStyles({
    title: {
      padding: spacing(3),
      marginTop: spacing(2),
    },
  }),
);
